
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function relatedItemsRT () {
    function repeatItems1(items, itemsIndex) {
        return [items(function () {
                function mergeProps(inline, external) {
                    var res = Object.assign({}, inline, external);
                    if (inline.hasOwnProperty('style')) {
                        res.style = _.defaults(res.style, inline.style);
                    }
                    if (inline.hasOwnProperty('className') && external.hasOwnProperty('className')) {
                        res.className = external.className + ' ' + inline.className;
                    }
                    return res;
                }
                return _createElement('li', {
                    'className': 'grid__item scroll-trigger animate--slide-in',
                    'data-cascade': true,
                    'style': { animationOrder: '1' }
                }, _createElement('div', { 'className': 'card-wrapper product-card-wrapper underline-links-hover' }, this.rebate_instant || this.rebate_mailin ? _createElement('div', {
                    'className': 'cm_rebate',
                    'key': '178'
                }, this.rebate_mailin ? _createElement('span', {
                    'className': 'cm_rebate_text',
                    'key': '258'
                }, '$', this.rebate_mailin, ' Rebate') : null, this.rebate_instant ? _createElement('span', {
                    'className': 'cm_rebate_text',
                    'key': '361'
                }, '$', this.rebate_instant, ' Instant Savings') : null) : null, _createElement('div', { 'className': 'card card--card card--media color-background-1 gradient' }, _createElement('h3', { 'className': 'card__heading card__heading--top h4' }, _createElement('a', mergeProps({
                    'className': 'card__heading-title',
                    'href': this.url
                }, { dangerouslySetInnerHTML: { __html: this.title } })), this.variant_title ? _createElement('span', {
                    'className': 'abz-bsw',
                    'key': '736'
                }, this.variant_title) : null), _createElement('div', { 'className': 'card__inner ratio' }, _createElement('a', { 'href': this.url }, _createElement('div', { 'className': 'card__media' }, _createElement('div', { 'className': 'media media--transparent media--hover-effect' }, _createElement('img', {
                    'className': 'motion-reduce',
                    'src': this.imageOrDefault(this.resizeImage(this.image)),
                    'alt': this.removeHTML(this.title),
                    'onError': this.onImageError
                })))), _createElement('div', { 'className': 'card__content' }, _createElement('div', { 'className': 'card__badge top right' }, !this.out_of_stock && this.on_sale ? _createElement('span', {
                    'className': 'badge badge--bottom-left color-accent-2',
                    'key': '1310'
                }, '\n              Sale\n            ') : null, this.out_of_stock ? _createElement('span', {
                    'className': 'badge badge--bottom-left color-accent-1',
                    'key': '1467'
                }, 'Sold Out') : null, this.vendor_image ? _createElement('div', {
                    'className': 'card-product-vendor',
                    'key': '1575'
                }, _createElement('img', {
                    'className': 'vendor-logo',
                    'src': this.vendor_image,
                    'onError': this.onImageError
                })) : null))), _createElement('div', { 'className': 'card__content' }, _createElement('div', { 'className': 'card__information' }, _createElement('div', { 'className': 'card-information' }, this.FitmentType.includes('Tire') ? _createElement('span', {
                    'className': 'caption-large light',
                    'key': '1923'
                }, 'Per Tire') : null, _createElement('div', { 'className': 'card-price-container' }, _createElement('div', { 'className': 'price price--on-sale' }, _createElement('div', { 'className': 'price__container' }, _createElement('div', { 'className': 'price__sale' }, this.on_sale ? [
                    _createElement('span', {
                        'className': 'visually-hidden visually-hidden--inline',
                        'key': '22321'
                    }, 'Regular price'),
                    _createElement('span', { 'key': '22323' }, _createElement('s', { 'className': 'price-item price-item--regular' }, '\n                          ', this.formatPrice(this.compare_at_price), '\n                        '))
                ] : null, _createElement('span', { 'className': 'visually-hidden visually-hidden--inline' }, 'Sale price'), _createElement('span', { 'className': 'price-item price-item--sale price-item--sale' }, '\n                      ', this.formatPrice(this.price), '\n                    ')))), this.price ? _createElement('p', {
                    'className': 'affirm-as-low-as',
                    'data-amount': this.price * 100,
                    'data-affirm-type': 'logo',
                    'data-affirm-color': 'blue',
                    'key': '2956'
                }) : null))), _createElement('ul', { 'className': 'abz-hazardlist' }, this.tire_warranty || this.tire_road_side_assistance || this.tire_road_hazard ? _createElement('h5', {
                    'className': 'abz-wtitle',
                    'key': '3184'
                }, '\n            Warranties\n          ') : null, this.tire_warranty ? _createElement('li', { 'key': '3343' }, 'Mileage ', this.tire_warranty[0]) : null, this.tire_road_side_assistance ? _createElement('li', { 'key': '3421' }, 'Free Road Hazard') : null, this.tire_road_hazard ? _createElement('li', { 'key': '3496' }, 'Free Roadside Assistance') : null), _createElement('a', {
                    'href': this.url,
                    'className': 'quick-add__submit button button--full-width button--secondary'
                }, '\n          View & Shop\n        ')))));
            }, { count: undefined })];
    }
    return _createElement('div', { 'className': 'cm_SearchResult' }, _createElement('div', { 'className': 'cm_related-products-title-container' }, _createElement('h2', { 'className': 'cm_related-products-title' }, 'You might also like...')), _createElement.apply(this, [
        'div',
        { 'className': 'grid cm_related-products-list cmRepeater_items' },
        _map(this.items, repeatItems1.bind(this))
    ]));
}
        export const componentNames = []