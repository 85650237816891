import shopifyDawnGenericDefaults from '../shopify-dawn-generic/config.js';

const baseFitmentFields = shopifyDawnGenericDefaults.fitmentSearch.baseFields;
const tireFields = shopifyDawnGenericDefaults.Widgets.find((w) => w.name === 'FacetBarTires').fields;
const rearTireFields = ['tire_width_rear', 'tire_aspect_rear', 'tire_rim_rear'];
const ignoreFields = [
  ...shopifyDawnGenericDefaults.Widgets.find((w) => w.name === 'Facets').ignoreFields,
  ...rearTireFields,
  'FrontRear',
];
const expandedFacetsFields = [
  'category',
  'global_treadtype',
  'vendor',
  'tire_road_condition',
  'price',
  'discounts',
];

shopifyDawnGenericDefaults.Widgets.find((w) => w.name === 'FacetBrandTiles').template =
  'facetTiles/hierarchicalContainerLetters';
shopifyDawnGenericDefaults.Widgets.forEach((w) => {
  if (w.type === 'FacetPanel' || w.name === 'FacetDialog') {
    w.ignoreFields = ignoreFields;
  }

  if (w.type === 'VehicleWidget') {
    w.fields = baseFitmentFields;
  }
});

const dialogContentEl = document.querySelector('.cm_search_dialog');

function responseHandler(response) {
  const vehicleTilesEl = dialogContentEl?.querySelector('#cm-vehicle-tiles');

  if (
    dialogContentEl?.classList.contains('active') &&
    dialogContentEl?.getAttribute('data-step') === '6' &&
    response.Extra.hasStaggeredTireSize
  ) {
    vehicleTilesEl.classList.add('show_disclaimer');
  } else if (dialogContentEl?.classList.contains('active')) {
    vehicleTilesEl.classList.remove('show_disclaimer');
  }

  return response;
}

// Redirect if user search Tires by vehicle
const onVehicleSelected = () => {
  if (
    dialogContentEl &&
    !dialogContentEl.classList.contains('cm_hide') &&
    dialogContentEl.getAttribute('data-attributes') === 'both'
  ) {
    window.location.assign('/pages/search');
  }
};

const getItemId = () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const variantId = urlParams.get('variant');

  if (variantId) {
    return variantId;
  }

  const sa = window.ShopifyAnalytics;
  if (sa && sa.meta.product.variants.length > 0) {
    return sa.meta.product.variants[0].id;
  }

  return '';
};

const InitFunc = () => {
  shopifyDawnGenericDefaults.InitFunc?.();

  const optionSelectors = window.document.querySelectorAll(
    'body.template-product .product-form__input select[name]',
  );

  // remove styles that add transform on parrent block on PLP
  // which blocks "position: fixed"
  document.querySelectorAll('.scroll-trigger.animate--slide-in').forEach((e) => {
    if (e.querySelector('#cm_results') || e.querySelector('#cm-collection-page')) {
      e.classList.remove('scroll-trigger', 'animate--slide-in');
    }
  });

  optionSelectors?.forEach((element) => {
    element.addEventListener('change', () => {
      setTimeout(() => {
        window.Convermax.updateVerifyFitmentWidget(getItemId());
      }, 300);
    });
  });
};

const baseUpdateCallback = shopifyDawnGenericDefaults.Widgets.find(
  (w) => w.name === 'SearchResult',
)?.updateCallback;
const updateCallback = () => {
  baseUpdateCallback?.();

  if (window.PayTomorrow) {
    window.PayTomorrow.mpeInit({
      debugMode: !1,
      enableMoreInfoLink: !1,
      logoColor: 'original',
      maxAmount: 5000,
      maxTerm: 6,
      minAmount: 30,
      mpeSelector: '.product-card-wrapper .card-mpe',
      priceSelector: '.product-card-wrapper .price__sale .price-item--sale',
      storeDisplayName: 'Race Treads',
      publicId: 'fcf0a20d0c0b7a3e42a406869af8e289',
    });
  }
};

const stickyScroll = () => {
  const ymmContainerEl = document.querySelector('.cm_vehicle-widget__sticky');

  if (ymmContainerEl) {
    const ymmEl = ymmContainerEl.querySelector('.cm_vehicle-widget__search-container');
    const headerEl = document.querySelector('sticky-header');

    if (ymmEl) {
      const headerHeight = headerEl.clientHeight;
      const ymmStartPos = ymmContainerEl.getBoundingClientRect();

      // use "+5" to get a more accurate if check
      if (ymmStartPos.top < headerHeight + 5) {
        ymmEl.classList.add('isSticky');
      } else {
        ymmEl.classList.remove('isSticky');
      }
    }
  }
};

// If user open dialog before our script upload, discard vehicle
const AfterInit = () => {
  if (document.body.classList.contains('cm_search') || document.body.classList.contains('cm_category')) {
    window.addEventListener('scroll', stickyScroll);
    window.addEventListener('resize', stickyScroll);
  }

  if (document.querySelector('.cm_search_dialog.active')) {
    window.Convermax.discardVehicle();
  }
};

export default {
  includes: ['shopify-dawn-generic'],
  ...shopifyDawnGenericDefaults,
  InitFunc,
  AfterInit,
  responseHandler,
  product: {
    ...shopifyDawnGenericDefaults.product,
    getItemId,
  },
  SearchRequestDefaults: {
    ...shopifyDawnGenericDefaults.SearchRequestDefaults,
    extra: {
      ...shopifyDawnGenericDefaults.SearchRequestDefaults.extra,
      isDialogOnPage: true,
    },
  },
  fitmentSearch: {
    ...shopifyDawnGenericDefaults.fitmentSearch,
    onVehicleSelected,
    isExtraFieldsAlwaysVisible: true,
  },
  pagination: {
    centralRange: 1,
    edgeRange: 0,
  },
  facets: {
    ...shopifyDawnGenericDefaults.facets,
  },
  Widgets: [
    ...shopifyDawnGenericDefaults.Widgets.filter(
      (w) => !['SearchResult', 'Facets', 'SearchHeader', 'VerifyFitment'].includes(w.name),
    ),
    {
      name: 'SearchResult',
      infiniteScrollDisabled: !!window.Convermax.config?.infiniteScrollDisabled,
      updateCallback,
    },
    {
      name: 'NonCollapsedFacets',
      type: 'FacetPanel',
      fields: expandedFacetsFields,
    },
    {
      name: 'CollapsedFacets',
      type: 'FacetPanel',
      initCollapsed: true,
      ignoreFields: [...ignoreFields, ...expandedFacetsFields],
    },
    {
      name: 'FrontRearFacetPanel',
      type: 'FacetPanel',
      fields: ['FrontRear'],
      showAllAlways: true,
      disableCollapse: true,
    },
    {
      name: 'SearchHeader',
      sortEntries: {
        'relevance': 'Best Match',
        'title': 'Name, A-Z',
        'title:desc': 'Name, Z-A',
        'collection_sort': 'Featured',
        'price': 'Price, Low to High',
        'price:desc': 'Price, High to Low',
      },
      sortSelectClass: 'select__select caption-large',
    },
    {
      name: 'SearchVehicleWidget_mobile',
      type: 'VehicleWidget',
      isAlwaysActive: true,
      isAlwaysColumnLayout: true,
      initCollapsed: true,
      fields: baseFitmentFields,
    },
    {
      name: 'VerifyFitment',
      location: { selector: '#cm-verify-fitment', class: 'cm_vehicle-widget__verify-fitment_container' },
      template: 'fitmentSearch/collapsibleVerifyFitment',
      initCollapsed: true,
    },
    {
      name: 'VehicleInfo',
    },
    {
      name: 'HeaderVehicleWidget_tiles',
      type: 'VehicleTiles',
      location: '#cm-vehicle-tiles',
      template: 'VehicleTiles',
    },
    {
      name: 'TirePanel_tab',
      type: 'RequestPanel',
      location: {
        selector: '#cm_dialog-tire',
        class: 'cm_vehicle-widget__tabs',
      },
      template: 'DialogTireBar',
      needInitRequest: true,
      isAutoRedirectDisabled: true,
      selectFields: [...tireFields, ...rearTireFields],
    },
  ],
};
