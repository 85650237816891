
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function VehicleTilesRT () {
    function repeatTileContainers1(tileContainers, tileContainersIndex) {
        return [tileContainers(function () {
                function onClick1(e) {
                    {
                        e.target.classList.contains('cm_vehicle-tiles__tile') && window.Convermax.changeStep('next');
                    }
                }
                function repeatTiles2(tiles, tilesIndex) {
                    return [tiles(function () {
                            return _createElement('a', { 'className': 'cm_vehicle-tiles__tile' }, this.value);
                        }, { count: undefined })];
                }
                function onClick3(e) {
                    {
                        e.target.classList.contains('cm_vehicle-tiles__tile') && window.Convermax.changeStep('next');
                    }
                }
                function repeatTiles4(tiles, tilesIndex) {
                    return [tiles(function () {
                            function scopeFrontSize1() {
                                var frontSize = this.value.split('|')[0];
                                return _createElement('div', {
                                    'className': 'cm_tiresize-tile-value',
                                    'key': '791'
                                }, _createElement('div', { 'className': 'cm_tiresize-tile-position' }, _createElement('div', { 'className': 'cm_tiresize-tile-position_name' }, 'FRONT:'), _createElement('div', { 'className': 'cm_tiresize-tile-position_size' }, frontSize.split('R')[1], '"')), _createElement('div', { 'className': 'cm_tiresize-tile-delimiter' }, '|'), _createElement('div', { 'className': 'cm_tiresize-tile-size' }, frontSize));
                            }
                            function scopeRearSize2() {
                                var rearSize = this.value.split('|')[1];
                                return _createElement('div', {
                                    'className': 'cm_tiresize-tile-value',
                                    'key': '793'
                                }, _createElement('div', { 'className': 'cm_tiresize-tile-position' }, _createElement('div', { 'className': 'cm_tiresize-tile-position_name' }, 'REAR:'), _createElement('div', { 'className': 'cm_tiresize-tile-position_size' }, rearSize.split('R')[1], '"')), _createElement('div', { 'className': 'cm_tiresize-tile-delimiter' }, '|'), _createElement('div', { 'className': 'cm_tiresize-tile-size' }, rearSize));
                            }
                            return _createElement('a', { 'className': 'cm_vehicle-tiles__tile' }, _createElement('div', { 'className': 'cm_tiresize-tile' }, this.value.includes('|') ? [
                                scopeFrontSize1.apply(this, []),
                                scopeRearSize2.apply(this, [])
                            ] : null, !this.value.includes('|') ? [_createElement('div', {
                                    'className': 'cm_tiresize-tile-value',
                                    'key': '11171'
                                }, _createElement('div', { 'className': 'cm_tiresize-tile-position' }, _createElement('div', { 'className': 'cm_tiresize-tile-position_name' }, this.value.includes('(F)') ? 'FRONT:' : ''), _createElement('div', { 'className': 'cm_tiresize-tile-position_size' }, this.value.split('R')[1], '"')), _createElement('div', { 'className': 'cm_tiresize-tile-delimiter' }, '|'), _createElement('div', { 'className': 'cm_tiresize-tile-size' }, this.value))] : null), _createElement('svg', {
                                'className': 'cm_tiresize-tile-arrow',
                                'width': '30',
                                'height': '24',
                                'viewBox': '0 0 30 24',
                                'fill': '#000',
                                'xmlns': 'http://www.w3.org/2000/svg'
                            }, _createElement('path', { 'd': 'M29.5607 13.0607C30.1464 12.4749 30.1464 11.5251 29.5607 10.9393L20.0147 1.3934C19.4289 0.807611 18.4792 0.807611 17.8934 1.3934C17.3076 1.97919 17.3076 2.92893 17.8934 3.51472L26.3787 12L17.8934 20.4853C17.3076 21.0711 17.3076 22.0208 17.8934 22.6066C18.4792 23.1924 19.4289 23.1924 20.0147 22.6066L29.5607 13.0607ZM0.5 13.5H28.5V10.5H0.5V13.5Z' })));
                        }, { count: undefined })];
                }
                function onClick5(e) {
                    e.target.classList.toggle('active');
                }
                return _createElement('div', { 'className': 'cm_vehicle-tiles__tiles-container ' + this.field.toLowerCase() }, this.field !== 'TireSize' ? [
                    _createElement('div', {
                        'className': 'cm_vehicle-tiles__tiles-title',
                        'key': '861'
                    }, 'What ', this.field.toLowerCase(), ' is your vehicle?'),
                    _createElement.apply(this, [
                        'div',
                        {
                            'onClick': onClick1.bind(this),
                            'className': 'cmRepeater_tiles',
                            'key': '863'
                        },
                        _map(this.tiles, repeatTiles2.bind(this))
                    ])
                ] : null, this.field === 'TireSize' ? [
                    _createElement('div', {
                        'className': 'cm_vehicle-tiles__tiles-title',
                        'key': '6780'
                    }, 'Choose tire attributes'),
                    _createElement.apply(this, [
                        'div',
                        {
                            'onClick': onClick3.bind(this),
                            'className': 'cmRepeater_tiles',
                            'key': '6782'
                        },
                        _map(this.tiles, repeatTiles4.bind(this))
                    ]),
                    _createElement('div', {
                        'className': 'cm_disclaimer',
                        'key': '6784'
                    }, _createElement('div', {
                        'className': 'cm_disclaimer-header',
                        'onClick': onClick5.bind(this)
                    }, [_createElement('svg', {
                            'className': 'cm_disclaimer-header-warn',
                            'version': '1.0',
                            'xmlns': 'http://www.w3.org/2000/svg',
                            'width': '512.000000pt',
                            'height': '512.000000pt',
                            'viewBox': '0 0 512.000000 512.000000',
                            'preserveAspectRatio': 'xMidYMid meet',
                            'key': '48080'
                        }, _createElement('g', {
                            'transform': 'translate(0.000000,512.000000) scale(0.100000,-0.100000)',
                            'fill': '#000000',
                            'stroke': 'none'
                        }, _createElement('path', { 'd': 'M2460 5110 c-201 -26 -414 -158 -520 -321 -20 -31 -453 -890 -963\n-1910 -742 -1484 -931 -1870 -947 -1933 -27 -107 -27 -265 0 -369 54 -213 200\n-396 391 -490 182 -90 -25 -81 2074 -85 1260 -2 1890 0 1952 8 170 19 321 91\n442 209 195 191 272 466 205 736 -25 101 -1861 3771 -1935 3869 -65 86 -160\n164 -254 210 -90 45 -148 62 -255 76 -92 11 -108 11 -190 0z m233 -478 c32\n-14 73 -42 92 -64 24 -26 334 -636 957 -1883 l923 -1845 -1 -77 c-1 -150 -81\n-255 -226 -298 -48 -14 -244 -15 -1899 -13 l-1844 3 -53 24 c-68 31 -145 114\n-167 182 -12 35 -16 72 -13 120 l4 70 921 1842 c738 1477 929 1850 959 1880\n95 95 218 116 347 59z' }), _createElement('path', { 'd': 'M2498 3786 c-57 -21 -85 -42 -116 -88 l-27 -42 -3 -688 c-2 -466 1\n-702 8 -729 23 -84 111 -149 205 -149 93 0 181 64 204 147 16 55 15 1368 0\n1414 -37 110 -166 174 -271 135z' }), _createElement('path', { 'd': 'M2474 1731 c-48 -22 -101 -82 -114 -131 -6 -19 -10 -80 -10 -136 0\n-87 3 -107 25 -151 66 -132 235 -160 339 -56 51 50 66 97 66 208 0 120 -9 158\n-52 209 -40 48 -98 76 -163 76 -27 0 -68 -9 -91 -19z' })))], _createElement('div', { 'className': 'cm_disclaimer-header-content' }, _createElement('div', { 'className': 'cm_disclaimer-header-text' }, '\n        Your vehicle was manufactured with more than one possible tire size.\n      '), _createElement('div', { 'className': 'cm_disclaimer-header-button' }, 'Find my tire size.'))), _createElement('div', { 'className': 'cm_disclaimer-content' }, _createElement('div', { 'className': 'cm_disclaimer-content-header' }, _createElement('h2', { 'className': 'cm_disclaimer-content-title' }, 'How do I find my tire size?'), _createElement('p', { 'className': 'cm_disclaimer-content-subtitle' }, 'The tire size for your vehicle can be found in two places:')), _createElement('div', { 'className': 'cm_disclaimer-info' }, _createElement('h3', { 'className': 'cm_disclaimer-info-title' }, 'On tire sidewall'), _createElement('img', {
                        'className': 'cm_disclaimer-image',
                        'src': window.Convermax.config.themeAssetsUrl + 'disclaimer-sidewall-1.png',
                        'alt': 'Tire Size фttributes transcript'
                    }), _createElement('img', {
                        'className': 'cm_disclaimer-image',
                        'src': window.Convermax.config.themeAssetsUrl + 'disclaimer-sidewall-2.png',
                        'alt': 'Attributes on tire sidewall'
                    })), _createElement('div', { 'className': 'cm_disclaimer-info' }, _createElement('h3', { 'className': 'cm_disclaimer-info-title' }, 'Inside the door jamb'), _createElement('img', {
                        'className': 'cm_disclaimer-image',
                        'src': window.Convermax.config.themeAssetsUrl + 'disclaimer-door-jamb-1.png',
                        'alt': 'Attributes on tire sidewall'
                    })), _createElement('div', { 'className': 'cm_disclaimer-info' }, _createElement('h3', { 'className': 'cm_disclaimer-info-title' }, 'Pros & Cons of Staggered Fitment'), _createElement('ul', { 'className': 'cm_disclaimer-info-list' }, _createElement('li', { 'className': 'cm_disclaimer-info-list-item' }, '\u2705Attractive Design'), _createElement('li', { 'className': 'cm_disclaimer-info-list-item' }, '\u2705Improved Handling'), _createElement('li', { 'className': 'cm_disclaimer-info-list-item' }, '\u2705Improved Cornering'), _createElement('li', { 'className': 'cm_disclaimer-info-list-item' }, '\u274CBumpier Ride'), _createElement('li', { 'className': 'cm_disclaimer-info-list-item' }, '\u274CPoor Traction in Snow')))))
                ] : null);
            }, { count: undefined })];
    }
    return _createElement('div', { 'className': 'cm_vehicle-tiles' }, _createElement('div', { 'className': 'cm_vehicle-tiles__chips-container' }, _createElement('div', { 'className': 'cm_vehicle-tiles__chips-title' }, 'Your Vehicle:'), [this.chips(function () {
            function onClick1(e) {
                {
                    e.target.classList.contains('cm_vehicle-tiles__chips-clear') && window.Convermax.changeStep(2);
                }
            }
            function repeatChipsRepeater2(chipsRepeater, chipsRepeaterIndex) {
                return [chipsRepeater(function () {
                        return _createElement('a', { 'className': 'cm_vehicle-tiles__chips-chip' }, this.value);
                    }, { count: undefined })];
            }
            return _createElement('div', {
                'className': 'cm_vehicle-tiles__chips cm_chips',
                'onClick': onClick1.bind(this)
            }, _createElement.apply(this, [
                'div',
                { 'className': 'cm_vehicle-tiles__chips-repeater cmRepeater_chipsRepeater' },
                _map(this.chipsRepeater, repeatChipsRepeater2.bind(this))
            ]), _createElement('a', {
                'className': 'cm_vehicle-tiles__chips-clear',
                'onClick': this.discardVehicle
            }, ' Start over '));
        }, {
            widgetName: 'undefined',
            items: undefined
        })]), _createElement.apply(this, [
        'div',
        { 'className': 'cm_vehicle-tiles__tiles-repeater cmRepeater_tileContainers' },
        _map(this.tileContainers, repeatTileContainers1.bind(this))
    ]));
}
        export const componentNames = ["cm:chips"]